import Link from 'next/link';
import _ from 'lodash';
import Image from 'next/image';
import { cdn } from 'data';
import { SkiStation } from '../../data/schema';

type Props = {
	station: SkiStation;
};

export const StationCard = ({ station }: Props) => {
	return (
		<Link
			href={`/estaciones/${station.code}`}
			title={`Estación de esquí, ski, snowboard de ${station.name}`}
			key={`station-card-${station.code}`}
			className="shadow-lg rounded-2xl w-64 m-4 p-4 py-6 bg-white">
			<div className="flex flex-col items-center justify-center">
				<div className="w-24 h-24 rounded-full flex p-1">
					<Image
						src={cdn(station.logo)}
						className="object-contain"
						alt={`Copitos de Nieve - Estaciones de esquí, ski, snowboard - Logo de la estación ${station.name}`}
						title={`Copitos de Nieve - Estaciones de esquí, ski, snowboard - Logo de la estación ${station.name}`}
						width={88}
						height={88}
						layout="fixed"
					/>
				</div>
				<h2 className="text-gray-800 text-xl font-medium mb-4 mt-4">{station.name}</h2>
				{/* <h3 className="text-gray-400 text-center text-xs px-2">{brand.shortDescription}</h3> */}
			</div>
		</Link>
	);
};
