import type { NextPage } from 'next';
import _ from 'lodash';
import { getStaticPropsNicho, NewsGridComponent } from 'ui';
import { data, db, News, Web } from 'data';
import { CopitosDeNieve, SkiStation } from '../data/schema';
import { aggregate, readItems } from '@directus/sdk';
import { StationCard } from '../components/stations/station-card';

type Props = {
	news: News[];
	randomStations: SkiStation[];
	web: Web;
};

const Home: NextPage<Props> = ({ news, randomStations, web }: Props) => {
	const stations = randomStations.map((station) => {
		return <StationCard station={station} key={station.code} />;
	});

	return (
		<div className="flex flex-col w-full">
			<section className="flex pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Últimas noticias
					</h2>
					<NewsGridComponent news={news} web={web} />
				</div>
			</section>
			<section className="pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Descubre alguna nueva estación de esquí
					</h2>
					<div className="flex flex-row">{stations}</div>
				</div>
			</section>
		</div>
	);
};

export const getStaticProps = getStaticPropsNicho(async () => {
	const cms = db<CopitosDeNieve>();
	const news = await data().news().getLastNews();
	const stations: SkiStation[] = [];

	const [stationsCount] = await cms.request(
		aggregate('cn_ski_stations', {
			aggregate: { count: '*' },
			filter: { status: { _eq: 'published' } }
		})
	);

	let count: number = parseInt(stationsCount.count!);

	for (let i = 0; i < 5; i++) {
		const offset = _.random(0, count - 1, false);

		const filter: any = { status: { _eq: 'published' } };

		if (stations.length > 0) {
			filter.id = { _nin: _.map(stations, (station) => station.id) };
		}

		const station = await cms.request<SkiStation>(
			readItems('cn_ski_stations', {
				filter,
				limit: 1,
				offset,
				sort: '-code'
			})
		);

		stations.push(station[0]);
		count--;
	}

	return {
		props: {
			news,
			randomStations: stations,
			title: 'Copitos de Nieve'
		}
	};
});

export default Home;
